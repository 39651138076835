export const ACCREDITED_REDUX_CONSTANTS = {
  GET_ACCREDITED_DETAILS: 'GET_ACCREDITED_DETAILS',
  CHANGE_ACCREDITION_DETAIL_AND_STEPPER_ID: 'CHANGE_ACCREDITION_DETAIL_AND_STEPPER_ID',
  CHANGE_ACCREDITION_DETAIL_STEPPER_ID: 'CHANGE_ACCREDITION_DETAIL_STEPPER_ID',

  RESET_ACCREDITED_DATA: 'RESET_ACCREDITED_DATA',

  GET_ACCREDITED_STEPPER: 'GET_ACCREDITED_STEPPER',
  UPDATE_FORM_FIELDS: 'UPDATE_FORM_FIELDS',
  UPDATE_SUB_FORM_FIELDS: 'UPDATE_SUB_FORM_FIELDS',
  UPDATE_SUB_FORM_ARRAY_FIELDS: 'UPDATE_SUB_FORM_ARRAY_FIELDS',
  UPDATE_SUB_FORM_DATA_ARRAY_FIELDS: 'UPDATE_SUB_FORM_DATA_ARRAY_FIELDS',

  // RE_ACCREDITED_CHECKBOX: {
  //   GET_RE_ACCREDITED_CHECKBOX_DETAILS: 'GET_RE_ACCREDITED_CHECKBOX_DETAILS',
  // },
  // unused

  POST_DETAILS: {
    GET_PRACTICE_MANAGERS_POST_DETAILS: 'GET_PRACTICE_MANAGERS_POST_DETAILS',
    GET_POST_DETAILS: 'GET_POST_DETAILS',
    UPDATE_POST_DETAILS_COPY_DATA: 'UPDATE_POST_DETAILS_COPY_DATA',
  },

  FORM_A: {
    // Practice Manager
    GET_PRACTICE_MANAGER_DETAILS: 'GET_PRACTICE_MANAGER_DETAILS',
    GET_PRACTICE_MANAGERS: 'GET_PRACTICE_MANAGERS',
    UPDATE_PRACTICE_MANAGER_TIMING: 'UPDATE_PRACTICE_MANAGER_TIMING',
    UPDATE_PRACTICE_MANAGER_COPY_DATA: 'UPDATE_PRACTICE_MANAGER_COPY_DATA',

    // standard
    GET_STANDARD_DETAILS: 'GET_STANDARD_DETAILS',
    UPDATE_STANDARD_COPY_DATA: 'UPDATE_STANDARD_COPY_DATA',

    // supervisor
    GET_SUPERVISOR_DATA: 'GET_SUPERVISOR_DATA',
    GET_SUPERVISORS: 'GET_SUPERVISORS',
    ADD_SUPERVISOR: 'ADD_SUPERVISOR',
    DELETE_SUPERVISOR: 'DELETE_SUPERVISOR',
    UPDATE_SUPERVISOR_TIMINGS: 'UPDATE_SUPERVISOR_TIMINGS',
    UPDATE_SUPERVISOR_COPY_DATA: 'UPDATE_SUPERVISOR_COPY_DATA',

    // registrar
    GET_REGISTRAR_DATA: 'GET_REGISTRAR_DATA',
    UPDATE_REGISTRAR_TIMING: 'UPDATE_REGISTRAR_TIMING',
    GET_REGISTRARS: 'GET_REGISTRARS',
    ADD_REGISTRAR: 'ADD_REGISTRAR',
    DELETE_REGISTRAR: 'DELETE_REGISTRAR',
    UPDATE_REGISTRAR_COPY_DATA: 'UPDATE_REGISTRAR_COPY_DATA',
  },

  FORM_A1: {
    // supervisor
    GET_A1_SUPERVISOR_DETAIL: 'GET_A1_SUPERVISOR_DETAIL',
    GET_A1_SUPERVISOR_DATA: 'GET_A1_SUPERVISOR_DATA',
    UPDATE_A1_SUPERVISOR_COPY_DATA: 'UPDATE_A1_SUPERVISOR_COPY_DATA',
  },

  PREVIOUS_RECOMMENDATIONS: {
    GET_PREVIOUS_RECOMMENDATIONS_DETAILS: 'GET_PREVIOUS_RECOMMENDATIONS_DETAILS',
    UPDATE_PREVIOUS_RECOMMENDATIONS_COPY_DATA: 'UPDATE_PREVIOUS_RECOMMENDATIONS_COPY_DATA',
  },

  FORM_B: {
    // summary
    GET_PRACTICE_MANAGERS_SUMMARY: 'GET_PRACTICE_MANAGERS_SUMMARY',
    GET_FORM_B_SUMMARY_DATA: 'GET_FORM_B_SUMMARY_DATA',
    UPDATE_FORM_B_SUMMARY_COPY_DATA: 'UPDATE_FORM_B_SUMMARY_COPY_DATA',

    // accreditorAssign
    GET_FORM_B_ACCREDITORS: 'GET_FORM_B_ACCREDITORS',
    GET_FORM_B_SELECTED_ACCREDITORS: 'GET_FORM_B_SELECTED_ACCREDITORS',
    UPDATE_FORM_B_ACCREDITORS_COPY_DATA: 'UPDATE_FORM_B_ACCREDITORS_COPY_DATA',

    // other details
    GET_FORM_B_OTHER_DETAILS: 'GET_FORM_B_OTHER_DETAILS',
    UPDATE_FORM_B_OTHER_DETAILS_COPY_DATA: 'UPDATE_FORM_B_OTHER_DETAILS_COPY_DATA',
  },
};
